<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="allTests"
      sort-by="calories"
      class="elevation-1"
      :loading="isGettingData"
      loading-text="يتم جلب البيانات ...الرجاء الانتظار"
    >
      <template v-slot:body.prepend>
        <tr>
          <td cols="12" sm="6">
            <v-text-field
              v-model="searchkey"
              label="تحديد رقم للبحث"
              prepend-icon="mdi-seach"
              clearable
            ></v-text-field>
          </td>
          <td colspan="2">
            <v-autocomplete
              ref="classes"
              v-model="classId"
              :items="classes"
              label="جلب حسب شعبة محدد"
              required
              item-text="name"
              item-value="id"
              chips
            ></v-autocomplete>
          </td>
          <td colspan="2">
            <v-autocomplete
              v-model="subjectId"
              :items="subjects"
              label="جلب حسب مادة محددة"
              item-text="name"
              item-value="id"
              chips
            ></v-autocomplete>
          </td>
          <td>
            <v-btn
              class="ma-2"
              outlined
              color="indigo"
              @click="searchTestResults"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="d-none d-sm-flex"
            >نتائج المذاكرات</v-toolbar-title
          >
          <v-divider class="mx-4 d-none d-sm-flex" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            class="d-none d-sm-flex"
            @click="showAddNewTest = true"
            bottom
            color="blue darken-4"
            dark
            fab
            fixed
            left
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- ******************************* Edit Dialoog*********************************-->
          <v-dialog v-model="dialog" width="400px">
            <v-card>
              <v-card-title class="accent white--text darken-2"
                >نتيجة جديدة</v-card-title
              >
              <v-container>
                <v-row justify="center">
                  <div>
                    <v-card flat width="300px" class="mx-auto">
                      <v-card-text>
                        <v-form ref="form" v-model="valid">
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-text-field
                                v-model="editItem.name"
                                label="الاسم"
                                :rules="nameRules"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-text-field
                                v-model="editItem.maxMark"
                                label="العلامة العظمى"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-autocomplete
                                v-model="editItem.subjectId"
                                :items="subjects"
                                label="المادة"
                                item-text="name"
                                item-value="id"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row class="mb-2">
                            <v-col cols="12">
                              <v-text-field
                                label="تاريخ"
                                value="1/1/001"
                                type="date"
                                v-model="editItem.date"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />

                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="save"
                  :loading="isClick"
                  >حفظ</v-btn
                >
                <v-btn text @click="dialog = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--******************************** Detail Dialog*********************************-->

          <v-dialog v-model="addTestDetailDialog" width="800px">
            <v-card>
              <v-card-title>
                <span class="headline">تثبيت العلامات</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col>
                        <v-data-table
                          :headers="studentMarkHeaders"
                          :items="studentMarks"
                        >
                            <template v-slot:body.prepend>
        <tr>
       <td colspan="2"></td>
          <td>
            <export-excel
              class="btn  btn-default outlined fab  ma-2"
              :data="studentMarks"
              :fields="exportHeaders"
              worksheet="My Worksheet"
              name="Marks.xls"
            >
              <v-btn outlined color="green"> تصدير البيانات</v-btn>
            </export-excel>
          </td>
          <td colspan="2"></td>
        </tr>
      </template>
                          <template v-slot:item.mark="props">
                            <v-edit-dialog
                              :return-value.sync="props.item.mark"
                              large
                              persistent
                            >
                              <div>{{ props.item.mark }}</div>
                              <template v-slot:input>
                                <div class="mt-4 title">Update Mark</div>
                              </template>
                              <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.mark"
                                  label="تعديل"
                                  single-line
                                  autofocus
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />

                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  @click="saveMark"
                  >حفظ</v-btn
                >
                <v-btn text @click="addTestDetailDialog = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!--******************************************-->
      <template v-slot:item.action="{ item }">
        <v-btn rounded color="primary" dark @click="editTestResult(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          rounded
          class="mr-2"
          color="error"
          @click="deleteTestResult(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          rounded
          class="mr-2"
          color="primary"
          @click="editTestDetail(item)"
          >تعديل العلامات</v-btn
        >
      </template>

      <template v-slot:no-data>لا يوجد أي برنامج محدد مسبقاً</template>
    </v-data-table>
    <AddTestResult :show.sync="showAddNewTest" @testAdded="refreshData()" />
  </div>
</template>

<script>
import { APIService } from "../../services/apiServices";
import AddTestResult from "../testsResults/AddTestResult.vue";
const apiService = new APIService();
export default {
  name: "TestResultIndex",
  components: { AddTestResult },
  data: () => ({
    dialog: false,
    valid: true,
    isClick: false,
    addTestDetailDialog: false,
    innerDialog: false,
    isGettingData: false,
    showAddNewTest: false,
    classId: 0,
    subjectId: 0,
    searchKey: "",
    headers: [
      {
        text: "النتائج",
        align: "start",
        value: "testName",
      },
      { text: "الشعبة", value: "className" },
      { text: "المادة", value: "subjectName" },
      { text: "التاريخ", value: "date" },
      { text: "العلامة العظمى", value: "maxMark" },
      { text: "العمليات", value: "action", sortable: false },
    ],
    exportHeaders: {
        "Student Number":"studentId",
      Name: "studentName",
      "Mark": "mark"
   
    },
    studentMarkHeaders: [
      {
        text: "رقم الطالب",
        align: "start",
        sortable: false,
        value: "studentId",
      },
      {
        text: "اسم الطالب",
        align: "start",
        sortable: false,
        value: "studentName",
      },
      { text: "العلامة", value: "mark" },
    ],
    allTests: [],
    studentMarks: [
      {
        id: 0,
        studentId: 0,
        studentName: "string",
        mark: 0.0,
      },
    ],
    classes: [],
    subjects: [],
    editedIndex: -1,
    test: {
      id: 0,
      // testName: "",
      // maxMark: 0,
      // date: "",
      // classId: 0,
      // subjectId: 0,

      details: [],
    },
    editItem: {
      id: 0,
      name: "",
      classId: "0",
      subjectId: 0,
      date: "",
      maxMark: 0,
    },
    defaultItem: {
      id: 0,
      name: "",
      classId: "0",
      subjectId: 0,
      date: "",
      maxMark: 0,
    },
    nameRules: [
      (v) => !!v || "يجب ادخال الاسم",
      (v) => (v && v.length <= 255) || "يجب أن يكون الاسم أقل من 255 حرف",
    ],
  }),
  created() {
    this.getTestResults();
    this.getClasses();
    this.getSubjects();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "مادة جديدة" : "تعديل";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    detailDialog(val) {
      if (val) {
        this.getSubjects();
      }
    },
  },

  methods: {
    getTestResults() {
      this.isGettingData = true;
      const that = this;
      apiService
        .getTestResults()
        .then((response) => {
          that.isGettingData = false;
          this.allTests = response.results;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    getClasses() {
      apiService
        .getClasses()
        .then((response) => {
          this.classes = response.classes;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
    searchTestResults() {
      this.isGettingData = true;
      const that = this;
      apiService
        .searchTestResults(this.classId, this.subjectId, this.searchkey)
        .then((response) => {
          that.isGettingData = false;
          this.allTests = response.results;
        })
        .catch(() => {
          that.isGettingData = false;
        });
    },
    async editTestDetail(item) {
      this.test.id = item.id;
      await apiService
        .getStudentInClassForResult(item.id)
        .then((response) => {
          this.studentMarks = response.studentsMark;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
      this.addTestDetailDialog = true;
    },

    deleteTestResult(item) {
      var testObject = {
        Id: item.id,
      };
      if (confirm("هل تريد بالتأكيد حذف هذه النتيجة مع تفاصيلها")) {
        setTimeout(() => {
          apiService
            .deleteTestResult(testObject)
            .then(() => {
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
    updatePro() {
      setTimeout(() => {
        apiService
          .updateTestProgramDetail(this.program)
          .then(() => {
            this.detailDialog = false;
          })
          .catch((error) => {
            this.error = "حدث خطأ ";
            alert(error);
            this.failed();
          });
      }, 3000);
    },
    saveMark() {
      this.test.details = this.studentMarks;
      setTimeout(() => {
        apiService
          .saveMark(this.test)
          .then((response) => {
            if (response != "") {
              this.test = {
                id: 0,

                details: [],
              };

              this.addTestDetailDialog = false;
            } else {
              // this.isClick = false;
            }
          })
          .catch((error) => {
            this.error = "حدث خطأ ";
            alert(error);
            this.failed();
          })
          .finally(() => {
            //this.isClick = false;
          });
      }, 1000);
    },
    getSubjects() {
      apiService
        .getSubjects()
        .then((response) => {
          this.subjects = response.subjects;
        })
        .catch((error) => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
    refreshData() {
      this.getTestResults();
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        this.isClick = true;
        setTimeout(() => {
          apiService
            .UpdateTestResult(this.editItem)
            .then(() => {
              this.isClick = false;
              this.editItem = Object.assign({}, this.defaultItem);
              this.close();
              this.refreshData();
            })
            .catch((error) => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            });
        }, 3000);
      }
    },
    deleteProDetail(item, id) {
      if (confirm("هل تريد بالتأكيد حذف هذا البند")) {
        const index = this.allTests
          .find((c) => c.id === id)
          .details.indexOf(item);
        if (index > -1)
          this.allTests.find((c) => c.id === id).details.splice(index, 1);
      }
    },

    editTestResult(item) {
      this.editedIndex = this.allTests.indexOf(item);

      this.editItem.id = item.id;
      this.editItem.name = item.testName;
      this.editItem.classId = item.classId;
      this.editItem.subjectId = item.subjectId;
      this.editItem.maxMark = item.maxMark;
      this.dialog = true;
    },
  },
};
</script>
