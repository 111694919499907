<template>
  <v-dialog v-model="dialog" width="400px">
    <v-card>
      <v-card-title class="accent white--text darken-2">نتيجة جديدة</v-card-title>
      <v-container>
        <v-row justify="center">
          <div>
            <v-card flat width="300px" class="mx-auto">
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-text-field v-model="test.name" label="الاسم" :rules="nameRules"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-text-field v-model="test.maxMark" label="العلامة العظمى"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="test.subjectId"
                        :items="subjects"
                        label="المادة"
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-text-field label="تاريخ" value="1/1/001" type="date" v-model="test.date"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-2">
                    <v-col cols="12">
                      <v-autocomplete
                        ref="classes"
                        v-model="test.classId"
                        :items="classes"
                        placeholder="اختر الشعبة  "
                        required
                        item-text="name"
                        item-value="id"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />

        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="addTest"
          :loading="isClick"
        >حفظ</v-btn>
        <v-btn text @click="dialog = false">الغاء</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//new
import { APIService } from "../../services/apiServices";
const apiService = new APIService();
export default {
  // name: "AddSubject",
  props: {
    show: Boolean
  },
  data: () => ({
    valid: true,
    addEnable: true,
    isClick: false,
    dialog: false,
    show1: false,
    classes: [],
    subjects: [],
    test: {
      id: 0,
      name: "",
      maxMark: 0,
      date: "",
      subjectId: 0,
      classId: 0
    },

    nameRules: [
      v => !!v || "يجب ادخال الاسم",
      v => (v && v.length <= 255) || "يجب أن يكون الاسم أقل من 255 حرف"
    ]
  }),
  methods: {
    addTest() {
      if (this.$refs.form.validate()) {
        this.isClick = true;
        this.addEnable = true;
        setTimeout(() => {
          apiService
            .addTest(this.test)
            .then(response => {
              if (response != "") {
                this.test = {
                  id: 0,
                  name: "",
                  maxMark: 0,
                  date: "",
                  subjectId: 0,
                  classId: 0
                };

                this.dialog = false;
                this.$emit("testAdded");
              } else {
                this.isClick = false;
              }
            })
            .catch(error => {
              this.error = "حدث خطأ ";
              alert(error);
              this.failed();
            })
            .finally(() => {
              this.isClick = false;
            });
        }, 3000);

        //this.dialog = false;
      }
    },
    getSubjects() {
      apiService
        .getSubjects()
        .then(response => {
          this.subjects = response.subjects;
        })
        .catch(error => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    },
    getClasses() {
      apiService
        .getClasses()
        .then(response => {
          this.classes = response.classes;
        })
        .catch(error => {
          this.error = "Unknown Error. Please check details and try again.";
          alert(error);
          this.failed();
        });
    }
  },

  watch: {
    show(val) {
      this.dialog = val;
    },

    dialog(val) {
      this.$emit("update:show", val);
      const that = this;
      setTimeout(() => {
        that.$refs.form.resetValidation();
      }, 300);
    }
  },

  created() {
    this.getClasses();
    this.getSubjects();
  }
};
</script>
